
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'

export default defineComponent({
  name: 'splash-view',
  components: {},
  setup() {
    const message = reactive({ value: ref('Authenticating ...') })

    const store = useStore()

    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading')

    return {
      message
    }
  }
})
